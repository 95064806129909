export const PUBLIC_PATHS = [
  '/login',
  '/itserve',
  '/404',
  '/401',
  '/forgotpassword',
  '/signup',
  '/logout',

  '/public/vendor-hub',
  '/public/company/profile/\\w*',
  '/public/uuid-converter',

  '/sl/\\w*',

  '/cp/\\w*',
  '/cp/\\w*/requests',
  '/cp/\\w*/requests/\\w*',
  '/cp/\\w*/\\w*',
  '/cp/\\w*/\\w*/forward-list',
  '/cp/\\w*/\\w*/subscribe',
  '/cp/\\w*/\\w*/candidate/\\w*',
  '/cp/\\w*/\\w*/candidate/\\w*/change-stage',
];

// TOP ADMIN LEVEL FOR ALL COMPANIES:
const commonAuthorizedPaths = [
  '/',
  '/setpassword',
  '/resetpassword',
  '/confirm-email',
  '/onboarding',
  '/onboarding/(welcome|set-password|create-company|about-yourself|personal-profile|company-profile|finished)',
];

// SHARED FEATURES FOR ALL COMPANY TYPES:
const commonFeaturesAuthorizedPaths = [
  '/account',
  '/account/user-profile',
  '/account/notifications',
  '/account/connected-accounts',
  '/connect_account/bullhorn',

  '/company/profile',
  '/company/profile/about',
  '/company/profile/users',
  '/company/profile/\\w*',
  '/company/profile/\\w*/about',
  '/company/profile/\\w*/users',

  '/user/profile',
  '/user/profile/\\w*',

  '/work-orders',
  '/work-orders/pending',
  '/work-orders/upcoming',
  '/work-orders/active',
  '/work-orders/declined',
  '/work-orders/archived',
  '/work-orders/\\w+',

  '/hotlist',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/edit/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/activate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/duplicate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/candidate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/request/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/complete-profile',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/edit/\\w*',

  '/settings',
  '/settings/company',
  '/settings/teams',
  '/settings/teams/new',
  '/settings/teams/\\w*/edit',
  '/settings/teams/\\w*/users',
  '/settings/members',
  '/settings/members/invite',
  '/settings/members/\\w*/remove',
  '/settings/members/\\w*/unassign-seat',
  '/settings/members/\\w*/teams',
  '/settings/members/\\w*/edit',
  '/settings/company-profile',
  '/settings/company-profile/users',
  '/settings/general',
  '/settings/shared-lists/\\w*',
  '/settings/shared-lists/themes',
  '/settings/shared-lists/themes/\\w*',
  '/settings/shared-lists/appearances',
  '/settings/shared-lists/appearances/new',
  '/settings/shared-lists/appearances/\\w*',
  '/settings/shared-lists/appearances/\\w*/(edit|duplicate)',
  '/settings/shared-lists/custom-fields',
  '/settings/shared-lists/custom-fields/new',
  '/settings/shared-lists/custom-fields/\\w*/edit',
  '/settings/shared-lists/client-portal',
  '/settings/shared-lists/email-templates',
  '/settings/shared-lists/email-templates/\\w*',
  '/settings/shared-lists/candidate-statuses',
  '/settings/shared-lists/notifications',

  '/settings/integrations',
  '/settings/integrations/\\w*',
  '/settings/integrations/\\w*/shared-lists',

  '/settings/ai',
  '/settings/ai/recruiter-note',
  '/settings/ai/ask-prompts',
  '/settings/ai/ask-prompts/\\w*',
  '/settings/ai/ask-prompts/\\w*/edit',
  '/settings/ai/ask-prompts/new',

  '/integrations/bullhorn',
  '/integrations/bullhorn/shared-lists',
  '/integrations/bullhorn/clients',
  '/integrations/bullhorn/candidates',

  '/subscription-plan',
  '/subscription-plan/setup',
  '/subscription-plan/add-seats',

  '/(shared|submission|marketing)-lists',
  '/(shared|submission|marketing)-lists/new/(job|marketing|connect)',
  '/(shared|submission|marketing)-lists/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|config|activity|messages)',
  '/(shared|submission|marketing)-lists/\\w*/activity/schedule',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/messages/\\w*/threads/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/(edit|activate|review|vms-submit)',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/request/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/(request|share)/\\w*/(edit|activate|review|vms-submit)',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/share/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/(request|share)/\\w*/(duplicate-candidate|remove-cv|delete-candidate|replace-cv|upload-cv|change-stage|reveal-cv|request-cv)',

  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*/(duplicate-candidate|remove-cv|delete-candidate|replace-cv|upload-cv|reveal-cv|request-cv|change-stage)',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/request/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/(activate|review)/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/(edit|add|activate|review|vms-submit)/\\w*',

  '/(shared|submission|marketing)-lists/\\w*/candidates/access-requests/\\w*/approve',
  '/(shared|submission|marketing)-lists/\\w*/candidates/submission-modal/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/request/\\w*/edit/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/config/general',
  '/(shared|submission|marketing)-lists/\\w*/config/appearance',
  '/(shared|submission|marketing)-lists/\\w*/config/candidate-statuses',
  '/(shared|submission|marketing)-lists/\\w*/config/automations',
  '/(shared|submission|marketing)-lists/\\w*/config/automations/new',
  '/(shared|submission|marketing)-lists/\\w*/config/automations/\\w*',
  '/(shared|submission|marketing)-lists/\\w*/config/automations/\\w*/edit',
  '/(shared|submission|marketing)-lists/\\w*/config/notifications',
  '/(shared|submission|marketing)-lists/\\w*/config/data-control',

  '/clients',
  '/clients/new',
  '/clients/find',
  '/clients/find/contacts',
  '/clients/\\w*',
  '/clients/\\w*/lists',
  '/clients/\\w*/contacts',
  '/clients/\\w*/contacts/new',
  '/clients/\\w*/contacts/\\w*/edit',
  '/clients/\\w*/contact/\\w*',
  '/clients/\\w*/contact/\\w*/edit',
  '/clients/\\w*/contact/\\w*/(lists|owners)',
  '/clients/\\w*/contact/\\w*/(lists|owners)/edit',

  '/reporting',
  '/reporting\\w*',

  '/test-reporting',
  '/test-reporting\\w*',

  '/messages',
  '/messages/\\w*',

  '/candidates',
  '/candidates/new',
  '/candidates/\\w*',
  '/candidates/\\w*/edit',
  '/candidates/\\w*/lists',
  '/candidates/\\w*/profiles',
  '/candidates/\\w*/profiles/add',
  '/candidates/\\w*/profiles/\\w*',
  '/candidates/\\w*/profiles/\\w*/(edit|edit_metadata|export)',
];

const administratorAuthorizedPaths = [
  '/',
  '/admin',

  '/admin/companies',
  '/admin/companies/list',
  '/admin/companies/users',
  '/admin/companies/invites',
  '/admin/companies/list/merge',

  '/admin/company/\\w*',
  '/admin/company/\\w*/users',
  '/admin/company/\\w*/settings',
  '/admin/company/\\w*/domains',
  '/admin/company/\\w*/domains/new',
  '/admin/company/\\w*/domains/\\w*/edit',
  '/admin/company/\\w*/credits',
  '/admin/company/\\w*/credits/new',
  '/admin/company/\\w*/credits/\\w*/edit',
  '/admin/company/\\w*/credits/transactions',

  '/admin/integrations',

  '/admin/messages',
  '/admin/messages/failed',
  '/admin/messages/processed',

  '/admin/subscription-plans',
  '/admin/subscription-plans/new',
  '/admin/subscription-plans/logs',
  '/admin/subscription-plans/\\w*',
  '/admin/subscription-plans/\\w*/edit',
  '/admin/subscription-plans/\\w*/companies',
  '/admin/subscription-plans/\\w*/companies/add',

  '/admin/hotlist',
  '/admin/hotlist/\\w*/match',
  '/admin/hotlist/\\w*/ui',
  '/admin/hotlist/\\w*/reindex',

  '/admin/data-analysis',

  '/admin/openai',
  '/admin/openai/prompts',
  '/admin/openai/prompts/\\w*',

  '/admin/settings',
  '/admin/settings/\\w*',
];

export const getAuthorizedPaths = (companyType) => {
  if (companyType === 'administrator') {
    return [...commonAuthorizedPaths, ...administratorAuthorizedPaths];
  }

  return [...commonAuthorizedPaths, ...commonFeaturesAuthorizedPaths];
};
